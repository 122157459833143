<template>
  <div class="index">
    travel- detail
  </div>
</template>

<script>
export default {
  name: "travel",
  components: {},
};
</script>
